export const queryKeys = {
  employees: "employees",
  allemployees: "allemployees",
  singleemployee: "singleemployee",
  departments: "departments",
  designations: "designations",
  institutes: "institutes",
  allowances: "allowances",
  deductions: "deductions",
  experiences: "experiences",
  educations: "educations",
  groups: "groups",
  leaves: "leaves",
  leavestypes: "leavestypes",
  leavesperiod: "leavesperiod",
  leavesperiodbymth: "leavesperiodbymth",
  leavesstatus: "leavesstatus",
  expenses: "expenses",
  expensesattachments: "expensesattachments",
  expensespayrun: "expensespayrun",
  expensesstatus: "expensesstatus",
  expensesperiod: "expensesperiod",
  payslips: "payslips",
  payslipsbatch: "payslipsbatch",
  payrun: "payrun",
  payitems: "payitems",
  payrunstatus: "payrunstatus",
  trainings: "trainings",
  family: "family",
  dailyallows: "dailyallows",
  dailyallowsperiod: "dailyallowsperiod",
  dailyallowsstatus: "dailyallowsstatus",
  dailyallowspayrun: "dailyallowspayrun",
  dailyallowsdetls: "dailyallowsdetls",
  user: "user",
  currency: "currency",
  hoc: "hoc",
  hocall: "hocall",
  hocperiod: "hocperiod",
  hoclookup: "hoclookup",
  hoclocation: "hoclocation",
  hoccategory: "hoccategory",
  hocwhat: "hocwhat",
  hocwhatdetails: "hocwhatdetails",
  hocwhy: "hocwhy",
  hocwhydetails: "hocwhydetails",
  yearendleavebal: "yearendleavebal",
  jobhistory: "jobhistory",
  jobstatus: "jobstatus",
  periods: "periods",
};
