// employees
export const GET_EMPLOYEES_BEGIN = "GET_EMPLOYEES_BEGIN";
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEES_ERROR = "GET_EMPLOYEES_ERROR";
export const GET_SINGLE_EMPLOYEE_BEGIN = "GET_SINGLE_EMPLOYEE_BEGIN";
export const GET_SINGLE_EMPLOYEE_SUCCESS = "GET_SINGLE_EMPLOYEE_SUCCESS";
export const GET_SINGLE_EMPLOYEE_ERROR = "GET_SINGLE_EMPLOYEE_ERROR";
export const DELETE_EMPLOYEE_BEGIN = "DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_ERROR = "DELETE_EMPLOYEE_ERROR";
export const UPDATE_EMPLOYEE_BEGIN = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_ERROR = "UPDATE_EMPLOYEE_ERROR";
export const ADD_EMPLOYEE_BEGIN = "ADD_EMPLOYEE_SUCCESS";
export const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS";
export const ADD_EMPLOYEE_ERROR = "ADD_EMPLOYEE_ERROR";
export const LOAD_EMPLOYEES = "LOAD_EMPLOYEES";
export const SET_SINGLEEMPLOYEE = "SET_SINGLEEMPLOYEE";
export const SET_EDITEMPLOYEEID = "SET_EDITEMPLOYEEID";
export const SET_ISEDITING_ON = "SET_ISEDITING_ON";
export const SET_ISEDITING_OFF = "SET_ISEDITING_OFF";
export const RESET_SINGLE_EMPLOYEE = "RESET_SINGLE_EMPLOYEE";
export const RESET_EMPLOYEES = "RESET_EMPLOYEES";

//leave
export const GET_LEAVES_BEGIN = "GET_LEAVES_BEGIN";
export const GET_LEAVES_SUCCESS = "GET_LEAVES_SUCCESS";
export const GET_LEAVES_ERROR = "GET_LEAVES_ERROR";
export const GET_SINGLE_LEAVE_BEGIN = "GET_SINGLE_LEAVE_BEGIN";
export const GET_SINGLE_LEAVE_SUCCESS = "GET_SINGLE_LEAVE_SUCCESS";
export const GET_SINGLE_LEAVE_ERROR = "GET_SINGLE_LEAVE_ERROR";
export const GET_SINGLEBATCH_LEAVE_BEGIN = "GET_SINGLEBATCH_LEAVE_BEGIN";
export const GET_SINGLEBATCH_LEAVE_SUCCESS = "GET_SINGLEBATCH_LEAVE_SUCCESS";
export const GET_SINGLEBATCH_LEAVE_ERROR = "GET_SINGLEBATCH_LEAVE_ERROR";
export const DELETE_LEAVE_BEGIN = "DELETE_LEAVE_SUCCESS";
export const DELETE_LEAVE_SUCCESS = "DELETE_LEAVE_SUCCESS";
export const DELETE_LEAVE_ERROR = "DELETE_LEAVE_ERROR";
export const UPDATE_LEAVE_BEGIN = "UPDATE_LEAVE_SUCCESS";
export const UPDATE_LEAVE_SUCCESS = "UPDATE_LEAVE_SUCCESS";
export const UPDATE_LEAVE_ERROR = "UPDATE_LEAVE_ERROR";
export const ADD_LEAVE_BEGIN = "ADD_LEAVE_SUCCESS";
export const ADD_LEAVE_SUCCESS = "ADD_LEAVE_SUCCESS";
export const ADD_LEAVE_ERROR = "ADD_LEAVE_ERROR";
export const LOAD_LEAVES = "LOAD_LEAVES";
export const SET_SINGLELEAVE = "SET_SINGLELEAVE";
export const SET_EDITLEAVEID = "SET_EDITLEAVEID";
export const SET_ISLEAVEEDITING_ON = "SET_ISLEAVEEDITING_ON";
export const SET_ISLEAVEEDITING_OFF = "SET_ISLEAVEEDITING_OFF";
export const RESET_SINGLE_LEAVE = "RESET_SINGLE_LEAVE";

//expense
export const GET_EXPENSES_BEGIN = "GET_EXPENSES_BEGIN";
export const GET_EXPENSES_SUCCESS = "GET_EXPENSES_SUCCESS";
export const GET_EXPENSES_ERROR = "GET_EXPENSES_ERROR";
export const GET_UNPAIDEXPENSES_BEGIN = "GET_UNPAIDEXPENSES_BEGIN";
export const GET_UNPAIDEXPENSES_SUCCESS = "GET_UNPAIDEXPENSES_SUCCESS";
export const GET_UNPAIDEXPENSES_ERROR = "GET_UNPAIDEXPENSES_ERROR";
export const GET_PERIODEXPENSES_BEGIN = "GET_PERIODEXPENSES_BEGIN";
export const GET_PERIODEXPENSES_SUCCESS = "GET_PERIODEXPENSES_SUCCESS";
export const GET_PERIODEXPENSES_ERROR = "GET_PERIODEXPENSES_ERROR";
export const GET_SINGLE_EXPENSE_BEGIN = "GET_SINGLE_EXPENSE_BEGIN";
export const GET_SINGLE_EXPENSE_SUCCESS = "GET_SINGLE_EXPENSE_SUCCESS";
export const GET_SINGLE_EXPENSE_ERROR = "GET_SINGLE_EXPENSE_ERROR";
export const DELETE_EXPENSE_BEGIN = "DELETE_EXPENSE_SUCCESS";
export const DELETE_EXPENSE_SUCCESS = "DELETE_EXPENSE_SUCCESS";
export const DELETE_EXPENSE_ERROR = "DELETE_EXPENSE_ERROR";
export const UPDATE_EXPENSE_BEGIN = "UPDATE_EXPENSE_SUCCESS";
export const UPDATE_EXPENSE_SUCCESS = "UPDATE_EXPENSE_SUCCESS";
export const UPDATE_EXPENSE_ERROR = "UPDATE_EXPENSE_ERROR";
export const ADD_EXPENSE_BEGIN = "ADD_EXPENSE_SUCCESS";
export const ADD_EXPENSE_SUCCESS = "ADD_EXPENSE_SUCCESS";
export const ADD_EXPENSE_ERROR = "ADD_EXPENSE_ERROR";
export const LOAD_EXPENSES = "LOAD_EXPENSES";
export const SET_SINGLEEXPENSE = "SET_SINGLEEXPENSE";
export const SET_EDITEXPENSEID = "SET_EDITEXPENSEID";
export const SET_ISEXPENSEEDITING_ON = "SET_ISEXPENSEEDITING_ON";
export const SET_ISEXPENSEEDITING_OFF = "SET_ISEXPENSEEDITING_OFF";
export const RESET_SINGLE_EXPENSE = "RESET_SINGLE_EXPENSE";

//training
export const GET_TRAININGS_BEGIN = "GET_TRAININGS_BEGIN";
export const GET_TRAININGS_SUCCESS = "GET_TRAININGS_SUCCESS";
export const GET_TRAININGS_ERROR = "GET_TRAININGS_ERROR";
export const GET_SINGLE_TRAINING_BEGIN = "GET_SINGLE_TRAINING_BEGIN";
export const GET_SINGLE_TRAINING_SUCCESS = "GET_SINGLE_TRAINING_SUCCESS";
export const GET_SINGLE_TRAINING_ERROR = "GET_SINGLE_TRAINING_ERROR";
export const GET_SINGLEBATCH_TRAINING_BEGIN = "GET_SINGLEBATCH_TRAINING_BEGIN";
export const GET_SINGLEBATCH_TRAINING_SUCCESS =
  "GET_SINGLEBATCH_TRAINING_SUCCESS";
export const GET_SINGLEBATCH_TRAINING_ERROR = "GET_SINGLEBATCH_TRAINING_ERROR";
export const DELETE_TRAINING_BEGIN = "DELETE_TRAINING_SUCCESS";
export const DELETE_TRAINING_SUCCESS = "DELETE_TRAINING_SUCCESS";
export const DELETE_TRAINING_ERROR = "DELETE_TRAINING_ERROR";
export const UPDATE_TRAINING_BEGIN = "UPDATE_TRAINING_SUCCESS";
export const UPDATE_TRAINING_SUCCESS = "UPDATE_TRAINING_SUCCESS";
export const UPDATE_TRAINING_ERROR = "UPDATE_TRAINING_ERROR";
export const ADD_TRAINING_BEGIN = "ADD_TRAINING_SUCCESS";
export const ADD_TRAINING_SUCCESS = "ADD_TRAINING_SUCCESS";
export const ADD_TRAINING_ERROR = "ADD_TRAINING_ERROR";
export const LOAD_TRAININGS = "LOAD_TRAININGS";
export const SET_SINGLETRAINING = "SET_SINGLETRAINING";
export const SET_EDITTRAININGID = "SET_EDITTRAININGID";
export const SET_ISTRAININGEDITING_ON = "SET_ISTRAININGEDITING_ON";
export const SET_ISTRAININGEDITING_OFF = "SET_ISTRAININGEDITING_OFF";
export const RESET_SINGLE_TRAINING = "RESET_SINGLE_TRAINING";

//payslips
export const GET_PAYSLIPS_BEGIN = "GET_PAYSLIPS_BEGIN";
export const GET_PAYSLIPS_SUCCESS = "GET_PAYSLIPS_SUCCESS";
export const GET_PAYSLIPS_ERROR = "GET_PAYSLIPS_ERROR";
export const GET_SINGLE_PAYSLIP_BEGIN = "GET_SINGLE_PAYSLIP_BEGIN";
export const GET_SINGLE_PAYSLIP_SUCCESS = "GET_SINGLE_PAYSLIP_SUCCESS";
export const GET_SINGLE_PAYSLIP_ERROR = "GET_SINGLE_PAYSLIP_ERROR";
export const GET_PENDING_PAYSLIP_BEGIN = "GET_PENDING_PAYSLIP_BEGIN";
export const GET_PENDING_PAYSLIP_SUCCESS = "GET_PENDING_PAYSLIP_SUCCESS";
export const GET_PENDING_PAYSLIP_ERROR = "GET_PENDING_PAYSLIP_ERROR";
export const GET_SINGLEBATCH_PAYSLIP_BEGIN = "GET_SINGLEBATCH_PAYSLIP_BEGIN";
export const GET_SINGLEBATCH_PAYSLIP_SUCCESS =
  "GET_SINGLEBATCH_PAYSLIP_SUCCESS";
export const GET_SINGLEBATCH_PAYSLIP_ERROR = "GET_SINGLEBATCH_PAYSLIP_ERROR";
export const DELETE_PAYSLIP_BEGIN = "DELETE_PAYSLIP_SUCCESS";
export const DELETE_PAYSLIP_SUCCESS = "DELETE_PAYSLIP_SUCCESS";
export const DELETE_PAYSLIP_ERROR = "DELETE_PAYSLIP_ERROR";
export const UPDATE_PAYSLIP_BEGIN = "UPDATE_PAYSLIP_SUCCESS";
export const UPDATE_PAYSLIP_SUCCESS = "UPDATE_PAYSLIP_SUCCESS";
export const UPDATE_PAYSLIP_ERROR = "UPDATE_PAYSLIP_ERROR";
export const ADD_PAYSLIP_BEGIN = "ADD_PAYSLIP_SUCCESS";
export const ADD_PAYSLIP_SUCCESS = "ADD_PAYSLIP_SUCCESS";
export const ADD_PAYSLIP_ERROR = "ADD_PAYSLIP_ERROR";
export const LOAD_PAYSLIPS = "LOAD_PAYSLIPS";
export const SET_SINGLEPAYSLIP = "SET_SINGLEPAYSLIP";
export const SET_EDITPAYSLIPID = "SET_EDITPAYSLIPID";
export const SET_ISPAYSLIPEDITING_ON = "SET_ISPAYSLIPEDITING_ON";
export const SET_ISPAYSLIPEDITING_OFF = "SET_ISPAYSLIPEDITING_OFF";
export const RESET_SINGLE_PAYSLIP = "RESET_SINGLE_PAYSLIP";
export const SET_PAYSLIP_PERIOD = "SET_PAYSLIP_PERIOD";
export const SET_PAYSLIP_ENDMONTHDATE = "SET_PAYSLIP_ENDMONTHDATE";
export const SET_PAYSLIPEARNING_AMOUNT = "SET_PAYSLIPEARNING_AMOUNT";
export const SET_PAYSLIPDEDUCTION_AMOUNT = "SET_PAYSLIPDEDUCTION_AMOUNT";
export const SET_PAYSLIPS_DATA = "SET_PAYSLIPS_DATA";
export const RESET_PAYSLIPS_DATA = "RESET_PAYSLIPS_DATA";
export const UPDATE_PAYSLIPS_DATA = "UPDATE_PAYSLIPS_DATA";

// payrun
export const GET_PAYRUN_BEGIN = "GET_PAYRUN_BEGIN";
export const GET_PAYRUN_SUCCESS = "GET_PAYRUN_SUCCESS";
export const GET_PAYRUN_ERROR = "GET_PAYRUN_ERROR";
export const GET_BATCHPAYRUN_BEGIN = "GET_BATCHPAYRUN_BEGIN";
export const GET_BATCHPAYRUN_SUCCESS = "GET_BATCHPAYRUN_SUCCESS";
export const GET_BATCHPAYRUN_ERROR = "GET_BATCHPAYRUN_ERROR";
export const ADD_PAYRUN_BEGIN = "ADD_PAYRUN_BEGIN";
export const ADD_PAYRUN_SUCCESS = "ADD_PAYRUN_SUCCESS";
export const ADD_PAYRUN_ERROR = "ADD_PAYRUN_ERROR";
export const UPDATE_PAYRUN_BEGIN = "UPDATE_PAYRUN_BEGIN";
export const UPDATE_PAYRUN_SUCCESS = "UPDATE_PAYRUN_SUCCESS";
export const UPDATE_PAYRUN_ERROR = "UPDATE_PAYRUN_ERROR";
export const DELETE_PAYRUN_BEGIN = "DELETE_PAYRUN_BEGIN";
export const DELETE_PAYRUN_SUCCESS = "DELETE_PAYRUN_SUCCESS";
export const DELETE_PAYRUN_ERROR = "DELETE_PAYRUN_ERROR";

// pay items
export const GET_PAYITEMS_BEGIN = "GET_PAYITEMS_BEGIN";
export const GET_PAYITEMS_SUCCESS = "GET_PAYITEMS_SUCCESS";
export const GET_PAYITEMS_ERROR = "GET_PAYITEMS_ERROR";
export const ADD_PAYITEM_BEGIN = "ADD_PAYITEM_BEGIN";
export const ADD_PAYITEM_SUCCESS = "ADD_PAYITEM_SUCCESS";
export const ADD_PAYITEM_ERROR = "ADD_PAYITEM_ERROR";
export const DELETE_PAYITEM_BEGIN = "DELETE_PAYITEM_BEGIN";
export const DELETE_PAYITEM_SUCCESS = "DELETE_PAYITEM_SUCCESS";
export const DELETE_PAYITEM_ERROR = "DELETE_PAYITEM_ERROR";
export const UPDATE_PAYITEM_BEGIN = "UPDATE_PAYITEM_BEGIN";
export const UPDATE_PAYITEM_SUCCESS = "UPDATE_PAYITEM_SUCCESS";
export const UPDATE_PAYITEM_ERROR = "UPDATE_PAYITEM_ERROR";

// payslip items
export const GET_PAYSLIPITEMS_BEGIN = "GET_PAYSLIPITEMS_BEGIN";
export const GET_PAYSLIPITEMS_SUCCESS = "GET_PAYSLIPITEMS_SUCCESS";
export const GET_PAYSLIPITEMS_ERROR = "GET_PAYSLIPITEMS_ERROR";
export const ADD_PAYSLIPITEM_BEGIN = "ADD_PAYSLIPITEM_BEGIN";
export const ADD_PAYSLIPITEM_SUCCESS = "ADD_PAYSLIPITEM_SUCCESS";
export const ADD_PAYSLIPITEM_ERROR = "ADD_PAYSLIPITEM_ERROR";
export const DELETE_PAYSLIPITEM_BEGIN = "DELETE_PAYSLIPITEM_BEGIN";
export const DELETE_PAYSLIPITEM_SUCCESS = "DELETE_PAYSLIPITEM_SUCCESS";
export const DELETE_PAYSLIPITEM_ERROR = "DELETE_PAYSLIPITEM_ERROR";
export const UPDATE_PAYSLIPITEM_BEGIN = "UPDATE_PAYSLIPITEM_BEGIN";
export const UPDATE_PAYSLIPITEM_SUCCESS = "UPDATE_PAYSLIPITEM_SUCCESS";
export const UPDATE_PAYSLIPITEM_ERROR = "UPDATE_PAYSLIPITEM_ERROR";

// payslip_earnings
export const GET_PAYSLIPEARNINGS_BEGIN = "GET_PAYSLIPEARNINGS_BEGIN";
export const GET_PAYSLIPEARNINGS_SUCCESS = "GET_PAYSLIPEARNINGS_SUCCESS";
export const GET_PAYSLIPEARNINGS_ERROR = "GET_PAYSLIPEARNINGS_ERROR";
export const GET_SINGLE_PAYSLIPEARNING_BEGIN =
  "GET_SINGLE_PAYSLIPEARNING_BEGIN";
export const GET_SINGLE_PAYSLIPEARNING_SUCCESS =
  "GET_SINGLE_PAYSLIPEARNING_SUCCESS";
export const GET_SINGLE_PAYSLIPEARNING_ERROR =
  "GET_SINGLE_PAYSLIPEARNING_ERROR";
export const DELETE_PAYSLIPEARNING_BEGIN = "DELETE_PAYSLIPEARNING_SUCCESS";
export const DELETE_PAYSLIPEARNING_SUCCESS = "DELETE_PAYSLIPEARNING_SUCCESS";
export const DELETE_PAYSLIPEARNING_ERROR = "DELETE_PAYSLIPEARNING_ERROR";
export const UPDATE_PAYSLIPEARNING_BEGIN = "UPDATE_PAYSLIPEARNING_SUCCESS";
export const UPDATE_PAYSLIPEARNING_SUCCESS = "UPDATE_PAYSLIPEARNING_SUCCESS";
export const UPDATE_PAYSLIPEARNING_ERROR = "UPDATE_PAYSLIPEARNING_ERROR";
export const ADD_PAYSLIPEARNING_BEGIN = "ADD_PAYSLIPEARNING_SUCCESS";
export const ADD_PAYSLIPEARNING_SUCCESS = "ADD_PAYSLIPEARNING_SUCCESS";
export const ADD_PAYSLIPEARNING_ERROR = "ADD_PAYSLIPEARNING_ERROR";
export const LOAD_PAYSLIPEARNINGS = "LOAD_PAYSLIPEARNINGS";

// payslip_deductions
export const GET_PAYSLIPDEDUCTIONS_BEGIN = "GET_PAYSLIPDEDUCTIONS_BEGIN";
export const GET_PAYSLIPDEDUCTIONS_SUCCESS = "GET_PAYSLIPDEDUCTIONS_SUCCESS";
export const GET_PAYSLIPDEDUCTIONS_ERROR = "GET_PAYSLIPDEDUCTIONS_ERROR";
export const GET_SINGLE_PAYSLIPDEDUCTION_BEGIN =
  "GET_SINGLE_PAYSLIPDEDUCTION_BEGIN";
export const GET_SINGLE_PAYSLIPDEDUCTION_SUCCESS =
  "GET_SINGLE_PAYSLIPDEDUCTION_SUCCESS";
export const GET_SINGLE_PAYSLIPDEDUCTION_ERROR =
  "GET_SINGLE_PAYSLIPDEDUCTION_ERROR";
export const DELETE_PAYSLIPDEDUCTION_BEGIN = "DELETE_PAYSLIPDEDUCTION_SUCCESS";
export const DELETE_PAYSLIPDEDUCTION_SUCCESS =
  "DELETE_PAYSLIPDEDUCTION_SUCCESS";
export const DELETE_PAYSLIPDEDUCTION_ERROR = "DELETE_PAYSLIPDEDUCTION_ERROR";
export const UPDATE_PAYSLIPDEDUCTION_BEGIN = "UPDATE_PAYSLIPDEDUCTION_SUCCESS";
export const UPDATE_PAYSLIPDEDUCTION_SUCCESS =
  "UPDATE_PAYSLIPDEDUCTION_SUCCESS";
export const UPDATE_PAYSLIPDEDUCTION_ERROR = "UPDATE_PAYSLIPDEDUCTION_ERROR";
export const ADD_PAYSLIPDEDUCTION_BEGIN = "ADD_PAYSLIPDEDUCTION_SUCCESS";
export const ADD_PAYSLIPDEDUCTION_SUCCESS = "ADD_PAYSLIPDEDUCTION_SUCCESS";
export const ADD_PAYSLIPDEDUCTION_ERROR = "ADD_PAYSLIPDEDUCTION_ERROR";
export const LOAD_PAYSLIPDEDUCTIONS = "LOAD_PAYSLIPDEDUCTIONS";

//daily allowances
export const GET_DAILYALLOWANCES_BEGIN = "GET_DAILYALLOWANCES_BEGIN";
export const GET_DAILYALLOWANCES_SUCCESS = "GET_DAILYALLOWANCES_SUCCESS";
export const GET_DAILYALLOWANCES_ERROR = "GET_DAILYALLOWANCES_ERROR";
export const GET_UNPAIDDAILYALLOWS_BEGIN = "GET_UNPAIDDAILYALLOWS_BEGIN";
export const GET_UNPAIDDAILYALLOWS_SUCCESS = "GET_UNPAIDDAILYALLOWS_SUCCESS";
export const GET_UNPAIDDAILYALLOWS_ERROR = "GET_UNPAIDDAILYALLOWS_ERROR";
export const GET_SINGLE_DAILYALLOWANCE_BEGIN =
  "GET_SINGLE_DAILYALLOWANCE_BEGIN";
export const GET_SINGLE_DAILYALLOWANCE_SUCCESS =
  "GET_SINGLE_DAILYALLOWANCE_SUCCESS";
export const GET_SINGLE_DAILYALLOWANCE_ERROR =
  "GET_SINGLE_DAILYALLOWANCE_ERROR";
export const GET_SINGLEBATCH_DAILYALLOWANCE_BEGIN =
  "GET_SINGLEBATCH_DAILYALLOWANCE_BEGIN";
export const GET_SINGLEBATCH_DAILYALLOWANCE_SUCCESS =
  "GET_SINGLEBATCH_DAILYALLOWANCE_SUCCESS";
export const GET_SINGLEBATCH_DAILYALLOWANCE_ERROR =
  "GET_SINGLEBATCH_DAILYALLOWANCE_ERROR";
export const DELETE_DAILYALLOWANCE_BEGIN = "DELETE_DAILYALLOWANCE_SUCCESS";
export const DELETE_DAILYALLOWANCE_SUCCESS = "DELETE_DAILYALLOWANCE_SUCCESS";
export const DELETE_DAILYALLOWANCE_ERROR = "DELETE_DAILYALLOWANCE_ERROR";
export const UPDATE_DAILYALLOWANCE_BEGIN = "UPDATE_DAILYALLOWANCE_SUCCESS";
export const UPDATE_DAILYALLOWANCE_SUCCESS = "UPDATE_DAILYALLOWANCE_SUCCESS";
export const UPDATE_DAILYALLOWANCE_ERROR = "UPDATE_DAILYALLOWANCE_ERROR";
export const ADD_DAILYALLOWANCE_BEGIN = "ADD_DAILYALLOWANCE_SUCCESS";
export const ADD_DAILYALLOWANCE_SUCCESS = "ADD_DAILYALLOWANCE_SUCCESS";
export const ADD_DAILYALLOWANCE_ERROR = "ADD_DAILYALLOWANCE_ERROR";
export const LOAD_DAILYALLOWANCES = "LOAD_DAILYALLOWANCES";
export const SET_SINGLEDAILYALLOWANCE = "SET_SINGLEDAILYALLOWANCE";
export const SET_EDITDAILYALLOWANCEID = "SET_EDITDAILYALLOWANCEID";
export const SET_ISDAILYALLOWANCEEDITING_ON = "SET_ISDAILYALLOWANCEEDITING_ON";
export const SET_ISDAILYALLOWANCEEDITING_OFF =
  "SET_ISDAILYALLOWANCEEDITING_OFF";
export const RESET_SINGLE_DAILYALLOWANCE = "RESET_SINGLE_DAILYALLOWANCE";
export const SET_DAILYALLOWANCE_PERIOD = "SET_DAILYALLOWANCE_PERIOD";

//daily allowances details
export const GET_DAILYALLOWSDETLS_BEGIN = "GET_DAILYALLOWSDETLS_BEGIN";
export const GET_DAILYALLOWSDETLS_SUCCESS = "GET_DAILYALLOWSDETLS_SUCCESS";
export const GET_DAILYALLOWSDETLS_ERROR = "GET_DAILYALLOWSDETLS_ERROR";
export const GET_SINGLE_DAILYALLOWSDETL_BEGIN =
  "GET_SINGLE_DAILYALLOWSDETL_BEGIN";
export const GET_SINGLE_DAILYALLOWSDETL_SUCCESS =
  "GET_SINGLE_DAILYALLOWSDETL_SUCCESS";
export const GET_SINGLE_DAILYALLOWSDETL_ERROR =
  "GET_SINGLE_DAILYALLOWSDETL_ERROR";
export const GET_PENDING_DAILYALLOWSDETL_BEGIN =
  "GET_PENDING_DAILYALLOWSDETL_BEGIN";
export const GET_PENDING_DAILYALLOWSDETL_SUCCESS =
  "GET_PENDING_DAILYALLOWSDETL_SUCCESS";
export const GET_PENDING_DAILYALLOWSDETL_ERROR =
  "GET_PENDING_DAILYALLOWSDETL_ERROR";
export const GET_SINGLEBATCH_DAILYALLOWSDETL_BEGIN =
  "GET_SINGLEBATCH_DAILYALLOWSDETL_BEGIN";
export const GET_SINGLEBATCH_DAILYALLOWSDETL_SUCCESS =
  "GET_SINGLEBATCH_DAILYALLOWSDETL_SUCCESS";
export const GET_SINGLEBATCH_DAILYALLOWSDETL_ERROR =
  "GET_SINGLEBATCH_DAILYALLOWSDETL_ERROR";
export const DELETE_DAILYALLOWSDETL_BEGIN = "DELETE_DAILYALLOWSDETL_SUCCESS";
export const DELETE_DAILYALLOWSDETL_SUCCESS = "DELETE_DAILYALLOWSDETL_SUCCESS";
export const DELETE_DAILYALLOWSDETL_ERROR = "DELETE_DAILYALLOWSDETL_ERROR";
export const UPDATE_DAILYALLOWSDETL_BEGIN = "UPDATE_DAILYALLOWSDETL_SUCCESS";
export const UPDATE_DAILYALLOWSDETL_SUCCESS = "UPDATE_DAILYALLOWSDETL_SUCCESS";
export const UPDATE_DAILYALLOWSDETL_ERROR = "UPDATE_DAILYALLOWSDETL_ERROR";
export const ADD_DAILYALLOWSDETL_BEGIN = "ADD_DAILYALLOWSDETL_SUCCESS";
export const ADD_DAILYALLOWSDETL_SUCCESS = "ADD_DAILYALLOWSDETL_SUCCESS";
export const ADD_DAILYALLOWSDETL_ERROR = "ADD_DAILYALLOWSDETL_ERROR";
export const LOAD_DAILYALLOWSDETLS = "LOAD_DAILYALLOWSDETLS";
export const SET_DAILYALLOWSDETL_PERIOD = "SET_DAILYALLOWSDETL_PERIOD";

//allowances
export const GET_ALLOWANCES_BEGIN = "GET_ALLOWANCES_BEGIN";
export const GET_ALLOWANCES_SUCCESS = "GET_ALLOWANCES_SUCCESS";
export const GET_ALLOWANCES_ERROR = "GET_ALLOWANCES_ERROR";
export const DELETE_ALLOWANCE_BEGIN = "DELETE_ALLOWANCE_SUCCESS";
export const DELETE_ALLOWANCE_SUCCESS = "DELETE_ALLOWANCE_SUCCESS";
export const DELETE_ALLOWANCE_ERROR = "DELETE_ALLOWANCE_ERROR";
export const UPDATE_ALLOWANCE_BEGIN = "UPDATE_ALLOWANCE_SUCCESS";
export const UPDATE_ALLOWANCE_SUCCESS = "UPDATE_ALLOWANCE_SUCCESS";
export const UPDATE_ALLOWANCE_ERROR = "UPDATE_ALLOWANCE_ERROR";
export const ADD_ALLOWANCE_BEGIN = "ADD_ALLOWANCE_SUCCESS";
export const ADD_ALLOWANCE_SUCCESS = "ADD_ALLOWANCE_SUCCESS";
export const ADD_ALLOWANCE_ERROR = "ADD_ALLOWANCE_ERROR";
export const LOAD_ALLOWANCES = "LOAD_ALLOWANCES";

//deductions
export const GET_DEDUCTIONS_BEGIN = "GET_DEDUCTIONS_BEGIN";
export const GET_DEDUCTIONS_SUCCESS = "GET_DEDUCTIONS_SUCCESS";
export const GET_DEDUCTIONS_ERROR = "GET_DEDUCTIONS_ERROR";
export const DELETE_DEDUCTION_BEGIN = "DELETE_DEDUCTION_SUCCESS";
export const DELETE_DEDUCTION_SUCCESS = "DELETE_DEDUCTION_SUCCESS";
export const DELETE_DEDUCTION_ERROR = "DELETE_DEDUCTION_ERROR";
export const UPDATE_DEDUCTION_BEGIN = "UPDATE_DEDUCTION_SUCCESS";
export const UPDATE_DEDUCTION_SUCCESS = "UPDATE_DEDUCTION_SUCCESS";
export const UPDATE_DEDUCTION_ERROR = "UPDATE_DEDUCTION_ERROR";
export const ADD_DEDUCTION_BEGIN = "ADD_DEDUCTION_SUCCESS";
export const ADD_DEDUCTION_SUCCESS = "ADD_DEDUCTION_SUCCESS";
export const ADD_DEDUCTION_ERROR = "ADD_DEDUCTION_ERROR";
export const LOAD_DEDUCTIONS = "LOAD_DEDUCTIONS";

//departments
export const GET_DEPARTMENTS_BEGIN = "GET_DEPARTMENTS_BEGIN";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_ERROR = "GET_DEPARTMENTS_ERROR";
export const DELETE_DEPARTMENT_BEGIN = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_ERROR = "DELETE_DEPARTMENT_ERROR";
export const UPDATE_DEPARTMENT_BEGIN = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_ERROR = "UPDATE_DEPARTMENT_ERROR";
export const ADD_DEPARTMENT_BEGIN = "ADD_DEPARTMENT_SUCCESS";
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS";
export const ADD_DEPARTMENT_ERROR = "ADD_DEPARTMENT_ERROR";
export const LOAD_DEPARTMENTS = "LOAD_DEPARTMENTS";

//designation
export const GET_DESIGNATIONS_BEGIN = "GET_DESIGNATIONS_BEGIN";
export const GET_DESIGNATIONS_SUCCESS = "GET_DESIGNATIONS_SUCCESS";
export const GET_DESIGNATIONS_ERROR = "GET_DESIGNATIONS_ERROR";
export const DELETE_DESIGNATION_BEGIN = "DELETE_DESIGNATION_SUCCESS";
export const DELETE_DESIGNATION_SUCCESS = "DELETE_DESIGNATION_SUCCESS";
export const DELETE_DESIGNATION_ERROR = "DELETE_DESIGNATION_ERROR";
export const UPDATE_DESIGNATION_BEGIN = "UPDATE_DESIGNATION_SUCCESS";
export const UPDATE_DESIGNATION_SUCCESS = "UPDATE_DESIGNATION_SUCCESS";
export const UPDATE_DESIGNATION_ERROR = "UPDATE_DESIGNATION_ERROR";
export const ADD_DESIGNATION_BEGIN = "ADD_DESIGNATION_SUCCESS";
export const ADD_DESIGNATION_SUCCESS = "ADD_DESIGNATION_SUCCESS";
export const ADD_DESIGNATION_ERROR = "ADD_DESIGNATION_ERROR";
export const LOAD_DESIGNATIONS = "LOAD_DESIGNATIONS";

//family
export const GET_FAMILY_BEGIN = "GET_FAMILY_BEGIN";
export const GET_FAMILY_SUCCESS = "GET_FAMILY_SUCCESS";
export const GET_FAMILY_ERROR = "GET_FAMILY_ERROR";
export const DELETE_FAMILY_BEGIN = "DELETE_FAMILY_SUCCESS";
export const DELETE_FAMILY_SUCCESS = "DELETE_FAMILY_SUCCESS";
export const DELETE_FAMILY_ERROR = "DELETE_FAMILY_ERROR";
export const UPDATE_FAMILY_BEGIN = "UPDATE_FAMILY_SUCCESS";
export const UPDATE_FAMILY_SUCCESS = "UPDATE_FAMILY_SUCCESS";
export const UPDATE_FAMILY_ERROR = "UPDATE_FAMILY_ERROR";
export const ADD_FAMILY_BEGIN = "ADD_FAMILY_SUCCESS";
export const ADD_FAMILY_SUCCESS = "ADD_FAMILY_SUCCESS";
export const ADD_FAMILY_ERROR = "ADD_FAMILY_ERROR";
export const LOAD_FAMILY = "LOAD_FAMILY";
export const LOAD_SINGLEBATCH_FAMILY_BEGIN = "LOAD_SINGLEBATCH_FAMILY_BEGIN";
export const LOAD_SINGLEBATCH_FAMILY_SUCCESS =
  "LOAD_SINGLEBATCH_FAMILY_SUCCESS";
export const LOAD_SINGLEBATCH_FAMILY_ERROR = "LOAD_SINGLEBATCH_FAMILY_ERROR";

//education
export const GET_EDUCATION_BEGIN = "GET_EDUCATION_BEGIN";
export const GET_EDUCATION_SUCCESS = "GET_EDUCATION_SUCCESS";
export const GET_EDUCATION_ERROR = "GET_EDUCATION_ERROR";
export const DELETE_EDUCATION_BEGIN = "DELETE_EDUCATION_SUCCESS";
export const DELETE_EDUCATION_SUCCESS = "DELETE_EDUCATION_SUCCESS";
export const DELETE_EDUCATION_ERROR = "DELETE_EDUCATION_ERROR";
export const UPDATE_EDUCATION_BEGIN = "UPDATE_EDUCATION_SUCCESS";
export const UPDATE_EDUCATION_SUCCESS = "UPDATE_EDUCATION_SUCCESS";
export const UPDATE_EDUCATION_ERROR = "UPDATE_EDUCATION_ERROR";
export const ADD_EDUCATION_BEGIN = "ADD_EDUCATION_SUCCESS";
export const ADD_EDUCATION_SUCCESS = "ADD_EDUCATION_SUCCESS";
export const ADD_EDUCATION_ERROR = "ADD_EDUCATION_ERROR";
export const LOAD_EDUCATION = "LOAD_EDUCATION";
export const LOAD_SINGLEBATCH_EDUCATION_BEGIN =
  "LOAD_SINGLEBATCH_EDUCATION_BEGIN";
export const LOAD_SINGLEBATCH_EDUCATION_SUCCESS =
  "LOAD_SINGLEBATCH_EDUCATION_SUCCESS";
export const LOAD_SINGLEBATCH_EDUCATION_ERROR =
  "LOAD_SINGLEBATCH_EDUCATION_ERROR";

//experience
export const GET_EXPERIENCE_BEGIN = "GET_EXPERIENCE_BEGIN";
export const GET_EXPERIENCE_SUCCESS = "GET_EXPERIENCE_SUCCESS";
export const GET_EXPERIENCE_ERROR = "GET_EXPERIENCE_ERROR";
export const DELETE_EXPERIENCE_BEGIN = "DELETE_EXPERIENCE_SUCCESS";
export const DELETE_EXPERIENCE_SUCCESS = "DELETE_EXPERIENCE_SUCCESS";
export const DELETE_EXPERIENCE_ERROR = "DELETE_EXPERIENCE_ERROR";
export const UPDATE_EXPERIENCE_BEGIN = "UPDATE_EXPERIENCE_SUCCESS";
export const UPDATE_EXPERIENCE_SUCCESS = "UPDATE_EXPERIENCE_SUCCESS";
export const UPDATE_EXPERIENCE_ERROR = "UPDATE_EXPERIENCE_ERROR";
export const ADD_EXPERIENCE_BEGIN = "ADD_EXPERIENCE_SUCCESS";
export const ADD_EXPERIENCE_SUCCESS = "ADD_EXPERIENCE_SUCCESS";
export const ADD_EXPERIENCE_ERROR = "ADD_EXPERIENCE_ERROR";
export const LOAD_EXPERIENCE = "LOAD_EXPERIENCE";
export const LOAD_SINGLEBATCH_EXPERIENCE_BEGIN =
  "LOAD_SINGLEBATCH_EXPERIENCE_BEGIN";
export const LOAD_SINGLEBATCH_EXPERIENCE_SUCCESS =
  "LOAD_SINGLEBATCH_EXPERIENCE_SUCCESS";
export const LOAD_SINGLEBATCH_EXPERIENCE_ERROR =
  "LOAD_SINGLEBATCH_EXPERIENCE_ERROR";

// reset tables
export const RESET_TABLES = "RESET_TABLES";
